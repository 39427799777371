import styled from 'styled-components';
import { MdAddCircleOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';
import { Container, Text, Pills, Pill } from 'ui';
import { Tooltip } from 'components';
import KoobBadge from './Koob/KoobBadge';

const PillsWrapper = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
`;

const RoomPills = ({
  selectedRoomIndex,
  onSelectRoomIndex,
  rooms,
  isUnsaved,
  addDisabled = false,
  translation,
  disabled,
}) => {
  const { t } = useTranslation(translation);

  return (
    <PillsWrapper>
      <Pills>
        {(rooms || []).map(({ node }, index) => (
          <Pill
            type="button"
            key={index}
            onClick={() => !isUnsaved && onSelectRoomIndex(index)}
            isActive={index === selectedRoomIndex}
          >
            {node?.displayName ||
              node?.enDisplayName ||
              node?.room?.displayName ||
              node?.room?.enDisplayName}

            {node?.isExternalSupplierOnly && (
              <KoobBadge className="ml-1.5" color="purple" size="sm">
                Ext-Only
              </KoobBadge>
            )}
          </Pill>
        ))}
        {!disabled && (
          <Tooltip disabled={!addDisabled} tip={t('selectEnglish')}>
            <Pill
              type="button"
              onClick={() =>
                !isUnsaved && !addDisabled && onSelectRoomIndex(null)
              }
              isActive={typeof selectedRoomIndex !== 'number'}
              disabled={addDisabled}
            >
              <Stack alignY="center" gutterSize={0.25}>
                <MdAddCircleOutline size={20} />
                <Text>{t('misc:add')}</Text>
              </Stack>
            </Pill>
          </Tooltip>
        )}
      </Pills>
    </PillsWrapper>
  );
};

export default RoomPills;
