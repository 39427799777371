import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, Routes, Route, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { RiBook3Line } from 'react-icons/ri';
import { MainNav, Stack } from '@koob/margaret';
import Logo from 'components/icons/Logo';
import {
  useApp,
  useAuth,
  useNotifications,
  useSearchParams,
  useTripDesigner,
} from 'hooks';
import { CounterBadge, fontStyles, Text } from 'ui';
import { GET_SETTING_REQUEST } from 'api/settingRequest';
import { useQuery } from '@apollo/client';
import { isNil, pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import { GET_TO_REQUEST_ACCESS } from 'api/toAccesRequest';
import { GET_DMC_REQUEST_ACCESS } from 'api/dmcAccesRequest';
import { VscTools } from 'react-icons/vsc';
import { BsBriefcase } from 'react-icons/bs';
import { useAsync } from 'react-async';
import { getRequestAccess } from 'api/node/dmcRequestAccess';
import { notificationType, permissionsEnum, stateEnum } from 'utils';

const SectionTitle = styled(Text).attrs({
  color: 'textLight',
  type: 'bodySmall',
  paddingHorizontal: 2,
})`
  text-transform: uppercase;
  font-weight: 600;
`;

const MainNavItems = styled(MainNav.Items).attrs({ gutterSize: 0.5 })`
  ${({ variant }) =>
    variant === 'nested' &&
    css`
      padding: 5px 0;
    `}
`;

export const SidebarNavLink = styled(Stack).attrs({
  as: NavLink,
  gutterSize: 0.5,
  alignY: 'center',
})`
  ${fontStyles.bodySmall}

  color: ${({ theme }) => theme.textLight};
  padding: ${({ theme }) => theme.spacing(0.3)}
    ${({ theme }) => theme.spacing()};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: background 150ms ease, color 150ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
    color: ${({ theme }) => theme.primary};
  }

  ${({ variant }) =>
    variant !== 'accordion' &&
    css`
      &:hover,
      &.active {
        color: ${({ theme }) => theme.primary};
        background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
      }
    `}

  ${({ variant }) =>
    variant === 'nested' &&
    css`
      padding-left: ${({ theme }) => theme.spacing(3)};
      padding-top: ${({ theme }) => theme.spacing(0.5)};
      padding-bottom: ${({ theme }) => theme.spacing(0.5)};
    `}

  svg {
    font-size: 1.5rem;
  }
`;

const MenuSetting = styled(Stack).attrs({
  gutterSize: 2,
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
})``;

const useFilteredQuery = kind => {
  return useQuery(GET_SETTING_REQUEST, {
    variables: pickBy(
      {
        discarded: false,
        state: stateEnum.PENDING,
        kind: kind,
      },
      value => !isNil(value),
    ),
  });
};

const MainSidebar = () => {
  const { isAllowedTo, currentOrganizationId, user, canManageToRequestAccess } =
    useApp();
  const { resetContext } = useTripDesigner();
  const { t } = useTranslation('mainSidebar');
  const { logout } = useAuth();
  const [{ state }] = useSearchParams();
  const { pathname } = useLocation();
  const { notifications } = useNotifications();
  const [isValidTripDesignerRequest, setIsValidTripDesignerRequest] = useState(
    localStorage.getItem('tripDesignerRequestState') === stateEnum.VALIDATED ??
      false,
  );
  const canDisplayTripNotification =
    pathname.split('/').indexOf('trip-designer') === -1;

  const { data, refetch } = useFilteredQuery(undefined);
  const { data: hotelSettingsPending, refetch: refetchHotelSettingCount } =
    useFilteredQuery('hotel');

  const { data: requestAccess, refetch: requestAccessRefetch } = useQuery(
    GET_TO_REQUEST_ACCESS,
    {
      variables: pickBy(
        {
          tripDesignerRequestState: stateEnum.PENDING,
          state: stateEnum.VALIDATED,
        },
        value => !isNil(value),
      ),
    },
  );

  const { data: dmcRequestAccess, refetch: dmcRequestAccessRefetch } = useQuery(
    GET_DMC_REQUEST_ACCESS,
    {
      variables: pickBy(
        {
          tripDesignerRequestState: stateEnum.PENDING,
        },
        value => !isNil(value),
      ),
    },
  );

  const { data: dmcRequest } = useAsync({
    promiseFn: getRequestAccess,
  });

  const {
    data: experienceSettingsPending,
    refetch: refetchExperienceSettingCount,
  } = useFilteredQuery('experience');

  const tripsNotificationsCounter = notifications.filter(
    notification =>
      [
        notificationType.TRIP_HELP_REQUESTED,
        notificationType.TRIP_HELP_ASSISTANT,
        notificationType.TRIP_NEW_VERSION_AVAILABLE,
        notificationType.TRIP_BOOKING_CONFIRMED,
      ].includes(notification.notificationType) && !notification.read,
  ).length;

  useEffect(() => {
    refetch();
    requestAccessRefetch();
    dmcRequestAccessRefetch();
    refetchHotelSettingCount();
    refetchExperienceSettingCount();
  }, [
    dmcRequestAccessRefetch,
    refetch,
    refetchExperienceSettingCount,
    refetchHotelSettingCount,
    requestAccessRefetch,
    state,
  ]);

  useEffect(() => {
    if (dmcRequest?.data?.tripDesignerRequestState) {
      setIsValidTripDesignerRequest(dmcRequest?.data?.tripDesignerRequestState);
      localStorage.setItem(
        'tripDesignerRequestState',
        dmcRequest?.data?.tripDesignerRequestState,
      );
    }
  }, [dmcRequest?.data?.tripDesignerRequestState]);

  const toRequestAccesCount = requestAccess?.toRequestAccess?.totalCount ?? 0;
  const dmcRequestAccesCount =
    dmcRequestAccess?.dmcRequestAccess?.totalCount ?? 0;

  const requestAccesCount = toRequestAccesCount + dmcRequestAccesCount;

  const settingCounts = {};
  const tab = data?.settingRequests.edges ?? [];
  for (const item of tab) {
    const settingName = item.node.settingName;
    settingCounts[settingName] = (settingCounts[settingName] || 0) + 1;
  }

  const totalExperienceSettingPending =
    experienceSettingsPending?.settingRequests?.totalCount === 0
      ? ''
      : experienceSettingsPending?.settingRequests?.totalCount;

  const totalPending =
    hotelSettingsPending?.settingRequests?.totalCount === 0
      ? ''
      : hotelSettingsPending?.settingRequests?.totalCount;

  const facility_group =
    (settingCounts['facility'] ?? 0) + (settingCounts['group'] ?? 0);
  const facilityCount = facility_group === 0 ? '' : facility_group;

  return (
    <MainNav style={{ zIndex: 11, paddingRight: 16 }}>
      <MainNav.Header alignX="center" size="full" marginTop={1}>
        <Logo />
      </MainNav.Header>

      <Scrollbars autoHide>
        <MainNav.MainContent
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <MainNavItems gutterSize={0.25}>
            {/* <MainNav.Item>
              <SidebarNavLink to="/" end>
                <IcDashboard />
                <span>{t('dashboard')}</span>
              </SidebarNavLink>
            </MainNav.Item> */}
            {/* <MainNav.Item>
              <SidebarNavLink to="billing">
                <IcCreditCard />
                <span>{t('billing')}</span>
              </SidebarNavLink>
            </MainNav.Item> */}

            {isAllowedTo('manageOrganizations') && (
              <MainNav.Item>
                <SidebarNavLink to="organizations">
                  <i className="far fa-building-magnifying-glass text-lg fa-fw" />

                  <MenuSetting>
                    <span>{t('organizations')}</span>
                    {isAllowedTo('manageHotelSettings') && (
                      <CounterBadge>
                        {requestAccesCount > 0 ? requestAccesCount : ''}
                      </CounterBadge>
                    )}
                  </MenuSetting>
                </SidebarNavLink>
              </MainNav.Item>
            )}

            {isAllowedTo('readSelfOrganization') && (
              <MainNav.Item>
                <SidebarNavLink to={`organizations/${currentOrganizationId}`}>
                  <i className="far fa-house-building text-lg fa-fw" />
                  <span>{t('myOrganization')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}

            {isAllowedTo('readBookings') && (
              <MainNav.Item>
                <SidebarNavLink to="bookings/my-bookings" variant="accordion">
                  <i className="far fa-ticket text-lg fa-fw" />
                  <span>{t('bookings')}</span>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="bookings/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="my-bookings" variant="nested">
                            <span>{t('booking')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>

                        <MainNav.Item>
                          <SidebarNavLink
                            to="partner-bookings"
                            variant="nested"
                          >
                            <span>{t('bookingPartners')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            <MainNav.Item>
              <SidebarNavLink to="hotels" variant="accordion">
                <i className="far fa-bed text-lg fa-fw" />
                <span>{t('hotels')}</span>
              </SidebarNavLink>

              <Routes>
                <Route
                  path="hotels/*"
                  element={
                    <MainNavItems variant="nested">
                      <MainNav.Item>
                        <SidebarNavLink to="catalog" variant="nested">
                          <span>{t('myCatalog')}</span>
                        </SidebarNavLink>
                      </MainNav.Item>
                      {isAllowedTo('createHotel') && (
                        <MainNav.Item>
                          <SidebarNavLink to="new" variant="nested">
                            <span>{t('searchNewHotel')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      )}
                    </MainNavItems>
                  }
                />
              </Routes>
            </MainNav.Item>

            <MainNav.Item>
              <SidebarNavLink to="experiences">
                <i className="far fa-layer-group text-lg fa-fw" />

                <span>{t('experiences')}</span>
              </SidebarNavLink>
            </MainNav.Item>

            {isAllowedTo(permissionsEnum.READ_BOOKINGS) && (
              <MainNav.Item>
                <SidebarNavLink
                  to={
                    !isValidTripDesignerRequest
                      ? 'trip-designer/'
                      : 'trip-designer/trips'
                  }
                  variant="accordion"
                >
                  <i className="far fa-cube text-lg fa-fw" />

                  <Stack direction="column">
                    <div className="flex items-center">
                      <span>{t('tripDesigner')}</span>
                      {canDisplayTripNotification &&
                        tripsNotificationsCounter > 0 && (
                          <CounterBadge>
                            {tripsNotificationsCounter}
                          </CounterBadge>
                        )}
                    </div>
                    {!isValidTripDesignerRequest && (
                      <div className={'text-sm text-orange-500'}>
                        {t('discover')}
                      </div>
                    )}
                  </Stack>
                </SidebarNavLink>

                {isValidTripDesignerRequest && (
                  <Routes>
                    <Route
                      path="trip-designer/*"
                      element={
                        <MainNavItems variant="nested">
                          <MainNav.Item>
                            <SidebarNavLink
                              to="design-trip"
                              variant="nested"
                              onClick={() => resetContext()}
                            >
                              <VscTools />
                              <span>{t('designTrip')}</span>
                            </SidebarNavLink>
                          </MainNav.Item>

                          <MainNav.Item>
                            <SidebarNavLink to="trips" variant="nested">
                              <BsBriefcase />
                              <span>{t('trips')}</span>
                            </SidebarNavLink>
                          </MainNav.Item>

                          <MainNav.Item>
                            <SidebarNavLink to="library/trips" variant="nested">
                              <div className="w-full flex items-center space-x-2">
                                <RiBook3Line />
                                <span className="whitespace-nowrap">
                                  {t('library')}
                                </span>
                                {tripsNotificationsCounter > 0 && (
                                  <CounterBadge>
                                    {tripsNotificationsCounter}
                                  </CounterBadge>
                                )}
                              </div>
                            </SidebarNavLink>
                          </MainNav.Item>
                        </MainNavItems>
                      }
                    />
                  </Routes>
                )}
              </MainNav.Item>
            )}

            {isAllowedTo('readUsers') && (
              <MainNav.Item>
                <SidebarNavLink to="users">
                  <i className="far fa-circle-user text-lg fa-fw" />
                  <span>{t('users')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}

            {isAllowedTo('readLocations') && (
              <MainNav.Item>
                <SidebarNavLink to="locations" variant="accordion">
                  <i className="far fa-globe text-lg fa-fw" />
                  <span>{t('locations')}</span>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="locations/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="countries" variant="nested">
                            <span>{t('countries')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="cities" variant="nested">
                            <span>{t('cities')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="markets" variant="nested">
                            <span>{t('markets')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}
          </MainNavItems>

          <SectionTitle paddingTop={2}>{t('settings')}</SectionTitle>

          <MainNavItems gutterSize={0.25}>
            {isAllowedTo('readHotelSettings') && (
              <MainNav.Item>
                <SidebarNavLink
                  to="settings/hotels/categories"
                  variant="accordion"
                >
                  <i className="far fa-bed text-lg fa-fw" />

                  <MenuSetting>
                    <span>{t('hotelsSettings')}</span>
                    {isAllowedTo('manageHotelSettings') && (
                      <CounterBadge>{totalPending}</CounterBadge>
                    )}
                  </MenuSetting>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="settings/hotels/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="categories" variant="nested">
                            <MenuSetting>
                              <span>{t('categories')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['category']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="styles" variant="nested">
                            <MenuSetting>
                              <span>{t('styles')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['style']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="locations" variant="nested">
                            <MenuSetting>
                              <span>{t('locations')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['location']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="accreditations" variant="nested">
                            <MenuSetting>
                              <span>{t('accreditations')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['accreditations']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="general-categories"
                            variant="nested"
                          >
                            <MenuSetting>
                              <span>{t('descriptions')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['descriptions']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="dmc-categories" variant="nested">
                            <MenuSetting>
                              <span>{t('dmcCategories')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['dmc-category']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="room-types" variant="nested">
                            <MenuSetting>
                              <span>{t('roomTypes')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['room']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="bed-types" variant="nested">
                            <MenuSetting>
                              <span>{t('bedTypes')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['bed']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="sustainable-clusters"
                            variant="nested"
                          >
                            <MenuSetting>
                              <span>{t('sustainableClusters')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['sustainable']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="facilities-groups"
                            variant="nested"
                          >
                            <MenuSetting>
                              <span>{t('facilitiesGroups')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>{facilityCount}</CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="meal-plans" variant="nested">
                            <MenuSetting>
                              <span>{t('mealPlans')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['meal-plan']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            {isAllowedTo('readExperienceSettings') && (
              <MainNav.Item>
                <SidebarNavLink to="settings/experiences" variant="accordion">
                  <i className="far fa-layer-group text-lg fa-fw" />

                  <MenuSetting>
                    <span>{t('experiencesSettings')}</span>
                    {isAllowedTo('manageHotelSettings') && (
                      <CounterBadge>
                        {totalExperienceSettingPending}
                      </CounterBadge>
                    )}
                  </MenuSetting>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="settings/experiences/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="segments" variant="nested">
                            <MenuSetting>
                              <span>{t('segments')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['segment']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>

                        <MainNav.Item>
                          <SidebarNavLink to="themes" variant="nested">
                            <MenuSetting>
                              <span>{t('themes')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['theme']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            {isAllowedTo('readTravelerSettings') && (
              <MainNav.Item>
                <SidebarNavLink to="settings/travelers" variant="accordion">
                  <i className="far fa-briefcase text-lg fa-fw" />
                  <span>{t('travelersSettings')}</span>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="settings/travelers/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="traveler-kinds" variant="nested">
                            <span>{t('categories')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            {(isAllowedTo('canManageToRequestAccess') ||
              canManageToRequestAccess(user)) && (
              <MainNav.Item>
                <SidebarNavLink to="to-request-access">
                  <i className="far fa-user-check text-lg fa-fw" />
                  <span>{t('requestAccess')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}

            <MainNav.Item>
              <SidebarNavLink to="to-connection-request">
                <i className="far fa-link text-lg fa-fw" />
                <span>{t('connectionRequest')}</span>
              </SidebarNavLink>
            </MainNav.Item>

            <MainNav.Item>
              <SidebarNavLink to="allotments">
                <i className="far fa-bolt text-lg fa-fw" />
                <span>{t('allotments')}</span>
              </SidebarNavLink>
            </MainNav.Item>
          </MainNavItems>

          <div className="mt-auto">
            <MainNavItems>
              <MainNav.Item>
                <SidebarNavLink
                  to="traveler-kinds"
                  onClick={logout}
                  variant="accordion"
                >
                  <i className="far fa-sign-out-alt text-lg fa-fw" />
                  <span>{t('signOut')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            </MainNavItems>

            <MainNavItems>
              <MainNav.Item>
                <a
                  href="https://docs.google.com/document/d/1GGkzrbXcatrITETOAxuE1f5Y_rV52IX7/edit?usp=sharing&ouid=115736253249552984829&rtpof=true&sd=true"
                  className="text-gray-500 hover:text-gray-700 transition"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="pl-4 flex justify-start items-center space-x-2">
                    <i className="far fa-file-alt text-lg fa-fw" />
                    <span>{t('userGuide')}</span>
                  </div>
                </a>
              </MainNav.Item>
            </MainNavItems>
          </div>
        </MainNav.MainContent>
      </Scrollbars>
    </MainNav>
  );
};

export default MainSidebar;
