import { useRef } from 'react';
import styled from 'styled-components';

import { Dropdown } from '@koob/margaret';
import Button from './Button';
import { IcCog } from './icons';

const MyButton = styled(Button)`
  position: relative;
`;

const MenuSetting = styled.div`
  text-align: center;
  margin-top: 5px;
`;

const BreadcrumbPopoverTrigger = ({
  icon = <IcCog size={20} color={'gray'} />,
}) => <MyButton>{icon}</MyButton>;

const DropdownElement = styled.div`
  position: relative;
  right: 90px;
`;

const DropdownConnectionSettings = ({ popoverElement }) => {
  const popoverRef = useRef();

  return (
    <>
      <MenuSetting>
        {Boolean(popoverElement) && (
          <Dropdown
            ref={popoverRef}
            trigger={
              <BreadcrumbPopoverTrigger
                style={{ position: 'relative', zIndex: 100 }}
              />
            }
          >
            <DropdownElement>
              <div style={{ position: 'relative', top: -35 }}>
                {popoverElement}
              </div>
            </DropdownElement>
          </Dropdown>
        )}
      </MenuSetting>
    </>
  );
};

export default DropdownConnectionSettings;
