import axios from 'axios';
import { camelizeKeys } from 'humps';
import { refreshToken, revokeToken } from 'api/auth';
import { API_NODE_BASE } from '../../constants';

const api = axios.create({ baseURL: `${API_NODE_BASE}/api/v1` });

const formatConfig = ({ params, ...opts } = {}) => ({
  ...opts,
});

api.interceptors.request.use(config => {
  const impersonationToken = localStorage.getItem('impersonationToken');
  const accessToken =
    impersonationToken?.length > 0
      ? impersonationToken
      : localStorage.getItem('accessToken');

  config.headers['Accept'] = 'application/json';

  if (Boolean(accessToken)) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    if (
      (error.response?.status === 400 && error.response?.data.message('Credentials are invalid.'))
      || (error.response?.status === 401 && error.response?.data.includes('Unauthorized'))
    ) {
      await revokeToken();
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      localStorage.setItem('impersonationToken', '');
      window.location.reload();
      return;
    }

    const originalRequest = error.config;
    const refreshTokenValue = localStorage.getItem('refreshToken');

    if (!error.response) {
      return Promise.reject(error);
    }

    if (originalRequest.url.indexOf('oauth/token') !== -1) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest._hasBeenRetried) {
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
    }

    if (
      error.response.status === 401 &&
      !originalRequest._hasBeenRetried &&
      Boolean(refreshTokenValue)
    ) {
      originalRequest._hasBeenRetried = true;

      return refreshToken({refreshToken: `${refreshTokenValue}`}).then(
        ({data}) => {
          localStorage.setItem('accessToken', data.accessToken);
          localStorage.setItem('refreshToken', data.refreshToken);

          originalRequest.headers[
            'Authorization'
            ] = `Bearer ${data.accessToken}`;
          return axios(originalRequest);
        },
        err => {
          localStorage.setItem('accessToken', '');
          localStorage.setItem('refreshToken', '');
        },
      );
    }

    return Promise.reject(error);
  },
);

const formatResponse = response => {
  if (!Boolean(response)) {
    return response;
  }

  return camelizeKeys(response);
};

export const get = (uri, config = {}, formatResp = true) =>
  api
    .get(uri, formatConfig(config))
    .then(formatResp ? formatResponse : response => response);

export const post = (uri, payload = {}, config, formatResp = true) =>
  api
    .post(uri, payload, formatConfig(config))
    .then(formatResp ? formatResponse : response => response);

export const put = (uri, payload = {}, config) =>
  api.put(uri, payload, formatConfig(config)).then(formatResponse);

export const patch = (uri, payload = {}, config) =>
  api.patch(uri, payload, formatConfig(config)).then(formatResponse);

export const destroy = (uri, payload = {}, config) =>
  api.delete(uri, payload, formatConfig(config)).then(formatResponse);

export const putFile = (uri, payload) =>
  api
    .put(uri, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(formatResponse);
