import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import React, { useEffect, useState } from 'react';
import { getHotelsContracts } from '../../../api/node/contracts';
import { getRoomsDetail } from '../../../utils/hotelDistribution';
import Header from './Partials/Header';
import HotelsSelected from './Partials/HotelsSelected';
import HotelsBrowser from './Partials/HotelsBrowser';
import { useApp, useError, useSnack } from '../../../hooks';
import { useAsync } from 'react-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@koob/margaret';
import {
  createToConnectionRequestHotels,
  getToConnectionRequestById,
  removeToConnectionRequestHotels,
  updateToConnectionRequest,
  updateToConnectionRequestHotels,
} from 'api/node/toConnectionRequest';
import KoobLoaderFullscreen from '@koob/ui/src/components/KoobLoaderFullscreen';

const ToConnectionRequestDetail = () => {
  const NB_OBJECTS_TO_SEND = 50;
  const { t } = useTranslation('toConnectionRequest');
  const { isAllowedTo, canManageToConnectionRequest, user } = useApp();
  const { notify } = useSnack();
  const { requestId } = useParams();
  const { sendErrorSnack } = useError();
  const navigate = useNavigate();

  const { data, isLoading } = useAsync({
    promiseFn: getToConnectionRequestById,
    requestId: requestId,
  });

  const request = data?.data;

  useEffect(() => {
    if (request) {
      setGlobalMargin(request.margin ?? 0);
      setIsGlobalMarginPercent(request.isMarginPercent ?? false);
    }

    if (request?.requestHotels) {
      let formattedHotels = request?.requestHotels.map((node) => ({
        id: node.hotel?.id,
        connectionRequestHotelId: node.id,
        displayName: node.hotel?.displayName,
        country: node.hotel?.city?.country?.alpha2,
        city: node.hotel?.city?.title,
        margin: node.margin,
        isMarginPercent: node.isMarginPercent,
        periods: [],
      }));

      setSelectedHotels(formattedHotels);
    }
  }, [request]);

  const [hotels, setHotels] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState(request?.hotels || []);
  const [globalMargin, setGlobalMargin] = useState(0);
  const [isGlobalMarginPercent, setIsGlobalMarginPercent] = useState(false);
  const [saving, setSaving] = useState(false);

  const isAllowedToManage = () => {
    return (
      isAllowedTo('canManageToConnectionRequest') ||
      canManageToConnectionRequest(user)
    );
  };

  const getRequestHotelIdsToRemove = (requestHotels, selectedHotels) => {
    return requestHotels?.reduce((requestsHotelsIds, requestHotel) => {
      const isHotelSelected = selectedHotels.some(
        (selectedHotel) => requestHotel.hotel?.id === selectedHotel.id,
      );

      if (!isHotelSelected) {
        requestsHotelsIds.push(requestHotel.id);
      }

      return requestsHotelsIds;
    }, []);
  };

  const divideArray = (array) => {
    const size = NB_OBJECTS_TO_SEND;
    const dividedArray = [];
    for (let i = 0; i < array.length; i += size) {
      dividedArray.push(array.slice(i, i + size));
    }
    return dividedArray;
  };

  const removeUnusedHotels = async () => {
    const toConnectionRequestHotelsIdsToRemove = getRequestHotelIdsToRemove(
      request?.requestHotels,
      selectedHotels,
    );
    if (toConnectionRequestHotelsIdsToRemove.length > 0) {
      const payloads = divideArray(toConnectionRequestHotelsIdsToRemove);
      for (const payload of payloads) {
        await removeToConnectionRequestHotels({
          toConnectionRequestId: requestId,
          toConnectionRequestHotelsIds: payload,
        });
      }
    }
  };

  const getTonnectionRequestHotelId = (id) => {
    const hotelConnectionRequest = request?.requestHotels?.find(
      (requestHotel) => requestHotel?.hotel?.id === id,
    );

    return hotelConnectionRequest?.id;
  };

  const getNewSelectedHotels = () => {
    return selectedHotels.filter(
      (selectHotel) => !getTonnectionRequestHotelId(selectHotel.id),
    );
  };

  const getRequestHotelsToUpdate = (requestHotels) => {
    return selectedHotels.reduce((updatedHotels, hotel) => {
      const hotelSelected = requestHotels.find(
        (requestHotel) => requestHotel.hotel?.id === hotel.id,
      );
      const hasChanges =
        hotelSelected &&
        (parseInt(hotelSelected?.margin) !== parseInt(hotel?.margin) ||
          hotelSelected?.isMarginPercent !== hotel?.isMarginPercent ||
          hotel?.periods?.length >= 1);

      if (hasChanges) {
        updatedHotels.push({...hotel, hotelId: hotel.id, enabled: true});
      }
      return updatedHotels;
    }, []);
  };

  const formatSelectedHotels = (selectedHotels) => {
    return selectedHotels.map((hotel) => ({
      id: getTonnectionRequestHotelId(hotel.id),
      hotelId: hotel.id,
      margin: hotel.margin ? parseInt(hotel.margin) : 0,
      isMarginPercent: hotel.isMarginPercent,
      periods: hotel.periods,
      enabled: true,
    }));
  };

  const save = async (state, comment) => {
    try {
      setSaving(true);

      await removeUnusedHotels();
      const newSelectedHotels = getNewSelectedHotels();

      const globalFields = {
        margin: globalMargin ? parseInt(globalMargin) : 0,
        isMarginPercent: isGlobalMarginPercent,
        state: state ?? request.state,
        explaination: comment?.reason,
      };

      await updateToConnectionRequest({
        toConnectionRequestId: requestId,
        payload: globalFields
      })

      if (newSelectedHotels.length > 0) {
        const newFormatSelectedHotels = formatSelectedHotels(newSelectedHotels);
        const payloads = divideArray(newFormatSelectedHotels);
        for (const payload of payloads) {
          await createToConnectionRequestHotels({
            toConnectionRequestId: requestId,
            payload: payload,
          });
        }
      }

      const updatedHotels = getRequestHotelsToUpdate(request?.requestHotels);

      if (updatedHotels.length > 0) {
        const updatedHotelsPayloads = divideArray(updatedHotels);
        for (const updatedHotelsPayload of updatedHotelsPayloads) {
          await updateToConnectionRequestHotels({
            toConnectionRequestId: requestId,
            payload: updatedHotelsPayload,
          });
        }
      }
      notify(t('updated'));
      navigate(-1);
    } catch (error) {
      sendErrorSnack(error);
    } finally {
      setSaving(false);
    }
  };

  const setHotelAttribute = (hotelId, attribute, value) => {
    setSelectedHotels((current) =>
      current.map((hotel) => {
        if (hotel.id === hotelId) {
          return {
            ...hotel,
            [attribute]: value,
          };
        }
        return hotel;
      }),
    );
  };

  const isHotelSelected = (hotelId) => {
    return selectedHotels.some((h) => h.id === hotelId);
  };

  const unselectHotel = (hotelId) => {
    setSelectedHotels(selectedHotels.filter((h) => h.id !== hotelId));
  };

  const convertRoomsToPeriod = (rooms) => {
    const periods = [];

    rooms.forEach(room => {
      room.periods.forEach(period => {
        const newPeriod = {
          id: period.id,
          allotmentCount: 0,
          freeSale: false,
          isPeriodAllowedForBooking: true,
          isMarginPercent: null,
          margin: null
        };

        periods.push(newPeriod);
      });
    });

    return periods;
  };

  const selectHotel = async (hotel) => {
    if (!isHotelSelected(hotel.id)) {
      hotel.margin = globalMargin;
      hotel.isMarginPercent = isGlobalMarginPercent;
      const contracts = await getHotelsContracts({ hotelId: hotel.id });
      const rooms= getRoomsDetail(
        contracts,
        [],
        request?.dmcorganization,
        request?.toorganization
      );

      hotel.periods = convertRoomsToPeriod(rooms);
      setSelectedHotels([...selectedHotels, hotel]);
    } else {
      unselectHotel(hotel.id);
    }
  };

  const selectAllShownHotels = (hotelsList) => {
    const hotelsNotSelected = hotelsList.filter(
      (hotel) => !isHotelSelected(hotel.id),
    );
    hotelsNotSelected.forEach((hotel) => {
      hotel.margin = globalMargin;
      hotel.isMarginPercent = isGlobalMarginPercent;
      hotel.periods = [];
    });
    setSelectedHotels([...selectedHotels, ...hotelsNotSelected]);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="py-4 px-4">
          <Header
            request={request}
            save={save}
            isLoading={saving}
            isAllowedToManage={isAllowedToManage()}
          />

          <div className="my-5 flex justify-end gap-5">
            {isAllowedToManage() && (
              <>
                {saving && (
                  <KoobLoaderFullscreen
                    opacity="semi"
                    title={t('toConnectionRequest:saving.title')}
                    description={t('toConnectionRequest:saving.description')}
                  />
                )}
                <Button type="button" variant="simple" to={-1}>
                  {t('misc:cancel')}
                </Button>
                <Button
                  onClick={() => save()}
                  variant="primary"
                  disabled={saving}
                >
                  {t('misc:save')}
                </Button>
              </>
            )}
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            <HotelsSelected
              selectedHotels={selectedHotels}
              setHotelAttribute={setHotelAttribute}
              onRemoveHotel={unselectHotel}
              dmcorganization={request?.dmcorganization}
              toorganization={request?.toorganization}
              isAllowedToManage={isAllowedToManage()}
            />

            {isAllowedToManage() && (
              <HotelsBrowser
                hotels={hotels}
                setHotels={setHotels}
                isHotelSelected={isHotelSelected}
                selectAllShownHotels={selectAllShownHotels}
                onAddHotel={selectHotel}
                globalMargin={globalMargin}
                setGlobalMargin={setGlobalMargin}
                isGlobalMarginPercent={isGlobalMarginPercent}
                setIsGlobalMarginPercent={setIsGlobalMarginPercent}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ToConnectionRequestDetail;
