import { gql } from '@apollo/client';

export const GET_ADMIN_BOOKING = gql`
  query getAdminBooking($id: ID!) {
    node(id: $id) {
      ... on AdminBooking {
        id
        bookingId
        adminBookingId
        accomCode
        bookingRef
        currency
        customerAdditionalAddress
        customerAddress
        customerCity
        customerCompany
        customerEmail
        customerFirstname
        customerLastname
        customerPhoneNumber
        customerInternalReference
        dmcReference
        toReference
        customerZipCode
        comment
        dmcState
        hotelKeeperMessage
        hotelKeeperMessageSendAt
        totalPrice
        dmcTotalPriceWithoutPromo
        allotmentAvailable
        hotelConfirmationNumber
        hotel {
          id
          displayName
          address
          stars
          cancelationPolicies
          contact {
            firstName
            lastName
            email
            phoneNumber
          }
        }
        kind
        note
        numberOfNights
        response
        roomCompositions {
          nodes {
            id
            dmcPrice
            dmcPricePerDay
            dmcPriceWithoutPromo
            dmcPriceWithoutMargin
            otherProposal
            cancelConditions
            tags
            bedCompositions {
              label
              quantity
            }
            hotelRoom {
              id
              displayName
            }
            travelers {
              nodes {
                id
                travelerId
                kind
                gender
                firstName
                lastName
                birthdate
                nationality
                passportNumber
                expirationDate
                ageIsExact
              }
              totalCount
            }
            promotions {
              kind
              promotionKind
              value
            }
          }
        }
        experienceCompositions {
          nodes {
            id
            price
            priceWithoutPromo
            priceWithoutMargin
            cancelConditions
            experience {
              id
              name
              inCity {
                title
                country {
                  title
                }
              }
            }
            travelers {
              nodes {
                id
                kind
                gender
                firstName
                lastName
                birthdate
                nationality
                passportNumber
                expirationDate
                ageIsExact
              }
              totalCount
            }
            promotions {
              kind
              promotionKind
              value
            }
          }
        }
        organization {
          id
          scope
        }
        rooms
        state
        supplierCode
        kind
        startAt
        endAt
        updatedAt
        networkName
        operatorTourName
        user {
          organization {
            id
            scope
            displayName
          }
        }
      }
    }
  }
`;

export const SEARCH_DMC_HOTEL_ROOMS = gql`
  query searchDmcHotelRooms($filters: SearchDmcRoomsInput!) {
    searchDmcHotelRooms(filters: $filters) {
      adminBooking {
        id
        numberOfNights
        currency
        roomCompositions {
          nodes {
            id
            travelers {
              nodes {
                id
                kind
              }
            }
          }
        }
      }
      possibilities {
        compositions {
          allotmentAvailable
          price
          totalPriceWithoutPromo
          priceWithPromo
          cancelConditions {
            deduction
            refundable
            startAt
            endAt
          }
          withEbPrice {
            id
            price
          }
          withEbTotalPriceWithPromo {
            id
            price
            cancelConditions {
              deduction
              refundable
              startAt
              endAt
            }
          }
          withEbTotalPriceWithoutPromo {
            id
            price
          }
          withEbTotalPriceWithPromo {
            id
            price
          }

          hotelRoom {
            id
            displayName
            description
            hotelRoomCompositions {
              nodes {
                id
                hrcId
                hotelRoomCompositionBeds {
                  nodes {
                    bedCount
                    id
                    bed {
                      displayName
                      enDisplayName
                      id
                    }
                  }
                }
              }
            }
          }
          promotions {
            nodes {
              id
              name
              kind
            }
          }
        }
      }
      hotel {
        id
        cancelationPolicies
      }
    }
  }
`;

export const UPDATE_ADMIN_BOOKING = gql`
  mutation updateAdminBooking($input: UpdateAdminBookingInput!) {
    updateAdminBooking(input: $input) {
      adminBooking {
        id
      }
      errors {
        message
        attribute
      }
    }
  }
`;

export const UPDATE_ADMIN_BOOKING_STATE = gql`
  mutation updateAdminBookingState($input: UpdateAdminBookingStateInput!) {
    updateAdminBookingState(input: $input) {
      adminBooking {
        id
        state
        hotelKeeperMessage
      }
      errors {
        message
        attribute
      }
    }
  }
`;
export const SUBMIT_ADMIN_BOOKING = gql`
  mutation submitAdminBooking($input: SubmitAdminBookingInput!) {
    submitAdminBooking(input: $input) {
      adminBooking {
        id
        currency
        startAt
        endAt
        customerAdditionalAddress
        customerAddress
        customerCity
        customerCompany
        customerEmail
        customerFirstname
        customerLastname
        customerPhoneNumber
        customerZipCode
        dmcReference
        dmcState
        state
        dmcTotalPriceWithoutPromo
        rooms
        kind
        totalPrice
        numberOfNights
        supplierCode
        note
        operatorTourName
        networkName
        response
        allotmentAvailable
        roomCompositions {
          nodes {
            dmcPriceWithoutPromo
            dmcPricePerDay
            dmcPrice
            hotelRoom {
              description
              displayName
            }
            bedCompositions {
              label
              quantity
            }
            promotions {
              kind
              promotionKind
              value
            }
            travelers {
              nodes {
                firstName
                lastName
                kind
                passportNumber
                expirationDate
                nationality
                birthdate
                roomComposition {
                  dmcPrice
                  hotelRoom {
                    displayName
                    capacity
                    room {
                      displayName
                      enDisplayName
                    }
                  }
                }
              }
            }
          }
        }
        hotel {
          id
          displayName
          address
          stars
        }
      }
      errors {
        message
        attribute
      }
    }
  }
`;

export const SELECT_DMC_HOTEL_ROOM = gql`
  mutation selectDmcHotelRooms($input: SelectDmcHotelRoomsInput!) {
    selectDmcHotelRooms(input: $input) {
      adminBooking {
        id
        roomCompositions {
          nodes {
            id
            sourceRoomId
            cancelConditions
            travelers {
              nodes {
                id
                kind
              }
            }
          }
        }
      }
      errors {
        path
        message
        attribute
        error
      }
    }
  }
`;

export const GET_ADMIN_BOOKING_DOCUMENTS = gql`
  query getAdminBookingDocuments($bookingId: ID!) {
    adminBookingDocuments(adminBookingId: $bookingId) {
      nodes {
        id
        user {
          firstName
          lastName
        }
        document {
          id
          metadata
        }
        private
        documentUrl
        createdAt
      }
    }
  }
`;

export const CREATE_ADMIN_BOOKING_DOCUMENT = gql`
  mutation createAdminBookingDocument(
    $input: CreateAdminBookingDocumentInput!
  ) {
    createAdminBookingDocument(input: $input) {
      adminBookingDocument {
        id
      }
    }
  }
`;

export const GET_ADMIN_BOOKING_MESSAGES = gql`
  query getAdminBookingMessages($bookingId: ID!) {
    adminBookingMessages(adminBookingId: $bookingId) {
      nodes {
        id
        senderType
        user {
          firstName
          lastName
        }
        message
        private
        createdAt
      }
    }
  }
`;

export const CREATE_ADMIN_BOOKING_MESSAGE = gql`
  mutation createAdminBookingMessage($input: CreateAdminBookingMessageInput!) {
    createAdminBookingMessage(input: $input) {
      adminBookingMessage {
        message
      }
    }
  }
`;

export const GET_BOOKINGS_TOTAL_COUNT = gql`
  query getBookingsCount($isTo: Boolean) {
    adminBookings(isTo: $isTo, withReplacedBookings: false) {
      totalCount
    }
  }
`;

export const GET_BOOKINGS_BY_DAY = gql`
  query getBookingsByDay(
    $endCursor: String
    $order: AdminBookingOrderCriteria
    $createdAt: ISO8601Date
    $rangeDates: BetweenDatesSearch
    $rangeDatesTravelPeriod: BetweenDatesSearch
    $search: String
    $isTo: Boolean
    $isHotel: Boolean
  ) {
    adminBookings(
      first: 15
      after: $endCursor
      order: $order
      createdAt: $createdAt
      rangeDates: $rangeDates
      rangeDatesTravelPeriod: $rangeDatesTravelPeriod
      search: $search
      isTo: $isTo
      isHotel: $isHotel
      withReplacedBookings: false
    ) {
      edges {
        node {
          id
          bookingId
          koobId
          allotmentAvailable
          customerFirstname
          customerLastname
          customerInternalReference
          dmcReference
          toReference
          currency
          createdAt
          dmcState
          startAt
          endAt
          state
          totalPrice
          supplierCode
          kind
          experienceCompositions {
            nodes {
              id
              allotment
              experience {
                id
                name
                inCity {
                  title
                  country {
                    title
                  }
                }
              }
            }
          }
          hotel {
            id
            displayName
          }
          user {
            id
            firstName
            lastName
          }
          numberOfNights
        }
      }
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const GET_BOOKINGS_COUNT_BY_EXPERIENCE = gql`
  query getBookingsByDay($isTo: Boolean, $isHotel: Boolean) {
    adminBookings(isTo: $isTo, isHotel: $isHotel) {
      edges {
        node {
          id
        }
      }
      totalCount
    }
  }
`;
