import React, { useCallback, useRef, useState } from 'react';
import { useAsync } from 'react-async';
import { Header, Button, Tooltip } from '../../components';
import { MdList } from 'react-icons/md';
import { Stack, SegmentedControl } from '@koob/margaret';
import LocationAwareSearch from '../../components/LocationAwareSearch';
import { Select as SelectField } from 'components/Fields';
import { useApp, useError, useSearchParams } from 'hooks';
import { IoMapOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ExperiencesListComponent from './List/partials/ExperiencesList';
import { useDeepCompareEffect } from 'react-use';
import { isEqual } from 'lodash';
import { Input, Link } from '../../ui';
import ExperiencesMap from './List/partials/ExperiencesMap';
import { exportExperiencesCompatibilities, getExperiences } from 'api/node/experiences';
import { GET_ORGANIZATIONS } from 'components/Forms/UserForm';
import { useQuery } from '@apollo/client';
import { getThemes } from 'api/node/themes';
import { getSegments } from 'api/node/segments';
import Select from 'react-select';
import { getLanguages } from 'utils/language';
import KoobGroupedButtons from 'components/Koob/KoobGroupedButtons';
import { DurationButton, DurationModal } from '@koob/experiences';

export default function ExperiencesList() {
  const HEIGHT = 42;
  const { t } = useTranslation('experiences');
  const headerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [kind, setKind] = useState('list');
  const [type, setType] = useState(undefined);
  const [selectedOrganization, setSelectedOrganization] = useState(undefined);
  const { state } = searchParams;
  const [page, setPage] = useState(1);
  const limitPerPage = 9;
  const [hasNextPage, setHasNextPage] = useState(true);
  const [experiences, setExperiences] = useState([]);
  const [extractProcessing, setExtractProcessing] = useState(false);
  const [filters, setfilters] = useState({});
  const [durationModalShown, setDurationModalShown] = useState(false);
  const [wrapped, setWrapped] = useState(false);

  const { user } = useApp();
  const experiencesFilters = {
    page,
    state: state,
    type: type?.value,
    organizationId: selectedOrganization?.value,
    search: searchParams.search ?? '',
    perPage: limitPerPage,
    themes: (filters?.themes || []).map(({ id }) => id),
    segments: (filters?.segments || []).map(({ id }) => id),
    startingPoint: filters?.startingPoint,
    endingPoint: filters?.endingPoint,
    minDuration: filters?.expDuration?.min,
    maxDuration: filters?.expDuration?.max,
    sustainableLevels: (filters?.sustainableLevels || [])
      .map(({ value }) => value)
      .filter(item => Boolean(item)),
    languages: (filters?.languages || []).map(({ value }) => value),
  };

  const { sendErrorSnack } = useError();
  const { data, isLoading } = useAsync({
    promiseFn: getExperiences,
    filters: experiencesFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });
  const stateOptions = [
    { value: null, label: t('state.all') },
    { value: 'available', label: t('state.available') },
    { value: 'in_progress', label: t('state.inProgress') },
    { value: 'stand_by', label: t('state.standBy') },
  ];
  const isEmpty = experiences.length === 0;

  const handleLoadNextPage = () => {
    setPage(page => page + 1);
  };

  useDeepCompareEffect(() => {
    if (data?.data) {
      setExperiences(experiences => [...experiences, ...data.data]);
      if (data?.data.length < limitPerPage) {
        setHasNextPage(false);
      }
    }
  }, [{ data }]);

  useDeepCompareEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setExperiences([]);
  }, [{ state, type, searchParams, selectedOrganization, filters }]);

  const { data: organizationData } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      discarded: false,
      isPartner: false,
    },
  });
  const organizationOptions = (
    organizationData?.organizations?.nodes ?? []
  )?.map(({ id, displayName }) => ({
    label: displayName,
    value: id,
  }));
  organizationOptions.unshift({
    label: 'Select organization',
    value: undefined,
  });

  const extractExperiencesCompatibilities = async () => {
    setExtractProcessing(true);
    try {
      const response = await exportExperiencesCompatibilities({
        filters: experiencesFilters,
      });
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const href = window.URL.createObjectURL(blob);

      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = 'experiences-compatibitilies.xlsx';
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    } catch (error) {
      sendErrorSnack(error);
    } finally {
      setExtractProcessing(false);
    }
  };

  const headerHeight = headerRef.current?.offsetHeight || 0;


  const { data: themesData } = useAsync({ promiseFn: getThemes });
  const { data: segmentsData } = useAsync({ promiseFn: getSegments });
  const sustainableLevels = [
    { label: t('form.zero'), value: 1 },
    { label: t('form.low'), value: 2 },
    { label: t('form.medium'), value: 3 },
    { label: t('form.high'), value: 4 },
  ];
  
  const updateFilterValue = useCallback(
    (property, value) => {
      setfilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setfilters],
  );

  return (
    <>
      <Header
        ref={headerRef}
        variant={kind === 'map' ? 'fixed' : undefined}
        rightElement={
          <Link to={'create'}>
            <Button variant="primary">{t('create')}</Button>
          </Link>
        }
      >
        <Stack
          alignX="space-between"
          alignY="center"
          size="full"
          gutterSize={1}
        >
          <Stack gutterSize={1}>
            <LocationAwareSearch name="search" />
            <SelectField
              value={type}
              onChange={value => setType(value)}
              name="Topology"
              options={[
                { label: t('state.all'), value: undefined },
                { label: t('transfer'), value: 'Transfer' },
                { label: t('types.activity'), value: 'Activity' },
                { label: t('types.extra'), value: 'Extra' },
                { label: t('types.program'), value: 'Program' },
              ]}
            />
            {user.role === 'koob_admin' && (
              <SelectField
                value={selectedOrganization}
                onChange={value => setSelectedOrganization(value)}
                name="organization"
                options={organizationOptions}
              />
            )}
            <Button
              type="button"
              variant="primary"
              onClick={extractExperiencesCompatibilities}
              disabled={extractProcessing || isEmpty}
            >
              {t('extractCompatibilities')}
            </Button>
          </Stack>

          <Stack alignY="center" gutterSize={1}>
            <KoobGroupedButtons
              value={wrapped}
              onSelect={value => setWrapped(value)}
              options={[
                {
                  value: false,
                  label: (
                    <Tooltip
                      tip={t('search.lessFilters')}
                      position="top"
                      hasArrow={true}>
                      <div className="py-0.5 flex space-x-0.5 items-center">
                        <svg className="h-4" viewBox="0 0 512 512">
                          <path
                            d="M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z"/>
                        </svg>
                        <svg className="h-3" viewBox="0 0 448 512">
                          <path
                            d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z"/>
                        </svg>
                      </div>
                    </Tooltip>
                  )
                },
                {
                  value: true,
                  label: (
                    <Tooltip
                      tip={t('search.moreFilters')}
                      position="top"
                      hasArrow={true}>
                      <div className="py-0.5 flex space-x-0.5 items-center">
                        <svg className="h-4" viewBox="0 0 512 512">
                          <path
                            d="M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z"/>
                        </svg>
                        <svg className="h-3" viewBox="0 0 448 512">
                          <path
                            d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"/>
                        </svg>
                      </div>
                    </Tooltip>
                  )
                },
              ]}
            />
            <SegmentedControl
              options={stateOptions}
              value={state ?? null}
              onSelect={value =>
                setSearchParams({
                  ...searchParams,
                  state: value,
                })
              }
            />
            <SegmentedControl
              value={kind}
              onSelect={value => setKind(value)}
              options={[
                { value: 'list', label: <MdList size={20} /> },
                { value: 'map', label: <IoMapOutline size={20} /> },
              ]}
            />
          </Stack>
        </Stack>

        {wrapped && (
          <Stack
            alignX="space-between"
            alignY="center"
            size="full"
          >
            <div className="flex flex-wrap space-x-4 space-y-3">
              <Input
                placeholder={t('search.startLocation')}
                defaultValue={filters.startingPoint}
                onKeyUp={event =>
                  updateFilterValue('startingPoint', event.target.value)
                }
                style={{
                  width: 'auto',
                  height: HEIGHT,
                  marginTop:'10px',
                  borderRadius: '20px',
                }}
              />
              <Input
                placeholder={t('search.endLocation')}
                defaultValue={filters.endingPoint}
                onKeyUp={event =>
                  updateFilterValue('endingPoint', event.target.value)
                }
                style={{
                  width: 'auto',
                  height: HEIGHT,
                  borderRadius: '20px',
                }}
              />

              <DurationButton
                translation={t}
                minDuration={filters?.expDuration?.min ?? 1}
                maxDuration={filters?.expDuration?.max}
                setDurationModalShown={setDurationModalShown}
                onCancel={() => updateFilterValue('expDuration', null)}
              />

              <Select
                placeholder={t('search.languages')}
                name="languages"
                options={getLanguages()}
                getOptionValue={value => value?.value}
                getOptionLabel={value => value?.label}
                value={filters?.languages}
                onChange={value => updateFilterValue('languages', value)}
                isMulti
                isSearchable={false}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    height: HEIGHT,
                    padding: '0 0.5rem',
                  }),
                }}
              />

              <Select
                placeholder={t('search.experienceTheme')}
                name="themes"
                options={themesData?.data}
                getOptionValue={value => value?.id}
                getOptionLabel={value => value?.displayName}
                value={filters?.themes}
                onChange={value => updateFilterValue('themes', value)}
                isMulti
                isSearchable={false}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    height: HEIGHT,
                    width: 'auto',
                    padding: '0 0.5rem',
                  }),
                }}
              />

              <Select
                placeholder={t('search.experienceSegment')}
                name="segments"
                options={segmentsData?.data}
                getOptionValue={value => value?.id}
                getOptionLabel={value => value?.displayName}
                value={filters?.segments}
                onChange={value => updateFilterValue('segments', value)}
                isMulti
                isSearchable={false}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    height: HEIGHT,
                    padding: '0 0.5rem',
                  }),
                }}
              />

              <Select
                placeholder={t('search.sustainableLevel')}
                name="sustainableLevel"
                options={sustainableLevels}
                getOptionValue={value => value?.value}
                getOptionLabel={value => value?.label}
                value={filters?.sustainableLevels}
                onChange={value => updateFilterValue('sustainableLevels', value)}
                isMulti
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    height: HEIGHT,
                    padding: '0 0.5rem',
                  }),
                }}
              />
            </div>
          </Stack>
        )}
      </Header>
      {kind === 'list' && (
        <ExperiencesListComponent
          experiences={experiences}
          onLoadNextPage={handleLoadNextPage}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          isEmpty={isEmpty}
        />
      )}
      {kind === 'map' && (
        <ExperiencesMap
          experiences={experiences}
          onLoadNextPage={handleLoadNextPage}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          isEmpty={isEmpty}
          headerHeight={headerHeight}
        />
      )}

     {durationModalShown && (
        <DurationModal
          open={durationModalShown}
          setOpen={setDurationModalShown}
          initialValue={{
            min: filters?.expDuration?.min ?? 0,
            max: filters?.expDuration?.max ?? 1,
          }}
          max={50}
          onSave={value => {
            updateFilterValue('expDuration', value)
            setDurationModalShown(false)
          }}
          translation={t}
        />
      )}
    </>
  );
}
