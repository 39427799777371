import moment from 'moment/moment';

export default function AllotmentsEditorHeader({ currentMonth, daysInMonth }) {
  return (
    <div className="bg-gray-50 py-3 px-4 text-gray-500 font-medium">
      <div className="flex space-x-3 items-center">
        <div className="w-52 flex justify-between items-center">
          <i className="far fa-bed"/>

          <div>
            {moment().month(currentMonth).format('MMMM YYYY')}
          </div>
        </div>

        {[...Array(daysInMonth)].map((_, i) => (
          <div
            key={i}
            className="w-12 text-center"
          >
            {i + 1 < 10 && '0'}
            {i + 1}
          </div>
        ))}
      </div>
    </div>
  );
}
