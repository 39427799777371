import { login } from 'api/auth';
import { Stack } from '@koob/margaret';
import { Button } from 'components';
import { FormGroup } from 'ui/forms';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth, useSnack } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthLayout from 'components/AuthLayout';
import { Navigate } from 'react-router-dom';
import { camelCase } from 'lodash';

const Login = () => {
  const { t } = useTranslation(['auth', 'errors']);
  const { onUpdate, accessToken } = useAuth();
  const { notify } = useSnack();

  const handleLogin = async values => {
    try {
      const { data } = await login(values);

      onUpdate(data);
    } catch (err) {
      const error = camelCase(err?.response?.data?.message ?? 'error');

      notify(t(`errors:${error}`), { type: 'error' });
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout title={t('login.title')} subtitle={t('login.subtitle')}>
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={Yup.object().shape({
          username: Yup.string().email().required(t('errors: required')),
          password: Yup.string().required(t('errors: required')),
        })}
        onSubmit={handleLogin}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <FormGroup>
              <TextField
                label={t('email')}
                id="login-username"
                autoComplete="email"
                placeholder=""
                name="username"
                type="email"
                tabIndex="1"
              />
              <TextField
                id="login-password"
                type="password"
                label={t('password')}
                name="password"
                autoComplete="off"
                tabIndex="1"
                labelSuffix={
                  <Button
                    variant="link"
                    to="/lost-access"
                    type="button"
                    tabIndex="2"
                  >
                    {t('login.forgotPassword')}
                  </Button>
                }
              />
            </FormGroup>
            <Stack marginTop={1.5}>
              <Button
                variant="primaryBig"
                size="full"
                isLoading={isSubmitting}
                tabIndex="2"
                id="login-button"
              >
                {t('login.button')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default Login;
