import { decodeId } from '../../../utils';

export function mergePreviousAndNewValues(previousValues, newValues) {
  return previousValues.map((room) => {
    const roomData = newValues.find(r => r.id === room.id);

    if (roomData) {
      return {
        ...room,
        organizations: room.organizations.map((org) => {
          const orgData = roomData.organizations.find(o => o.id === org.id);

          if (orgData) {
            const newDays = orgData.allotmentDays
              ?.filter(ad => !org.allotmentDays.some(a => a.id === ad.id));

            return {
              ...org,
              allotmentDays: [
                ...org.allotmentDays,
                ...newDays
              ]
            };
          }

          return org;
        })
      };
    }

    return room;
  });
}

export function removeUntouchedValues(values) {
  return values
    ?.filter(r => r.edited || r.organizations?.some(o => o.edited))
    ?.map((room) => ({
      ...room,
      organizations: room.organizations
        ?.filter(org => org.edited)
        ?.map(org => {
          const allotmentDays = org.allotmentDays
          ?.filter(ad => ad.edited)
          ?.map(ad => ({
            ...ad,
            edited: undefined
          }))
          .reduce((acc, item) => {
            acc[item.day] = acc[item.day] || item;
            return acc;
          }, {})
          return {
            ...org,
            allotmentDays: Object.values(allotmentDays),
          edited: undefined
          }
        }),
      edited: undefined
    }));
}

export function applyAllotmentEdits({ rooms, selectedRooms, selectedOrganizations, selectedDays, state, count }) {
  return rooms?.map(room => {
    if (selectedRooms.some(selectedRoom => selectedRoom.id === room.id)) {
      const newRoom = {
        ...room,
        edited: true,
      };

      newRoom.organizations = room.organizations.map(org => {
        if (selectedOrganizations.some(selectedOrg => selectedOrg.id === org.id)) {
          const newOrg = {
            ...org,
            edited: true,
          };

          // Days that are not in the original allotment
          const newDays = selectedDays.filter(selectedDay =>
            selectedDay.roomId === room.id && !selectedDay.id
          );

          newOrg.allotmentDays = [
            ...newOrg.allotmentDays.map(day => {
              if (selectedDays.some(selectedDay => selectedDay.day === day.day && selectedDay.roomId === room.id)) {
                return {
                  ...day,
                  state: state ?? day.state,
                  count: count ?? day.count,
                  edited: true,
                };
              }

              return day;
            }),
            ...newDays.map(day => ({
              ...day,
              periodId: day.periodId ?? room.organizations?.find(o =>
                o.allotmentDays?.some(ad => ad.day === day.day)
              )?.allotmentDays?.find(ad => ad.day === day.day)?.periodId,
              state: state ?? day.state,
              count: count ?? day.count,
              edited: true,
            }))
          ];

          return newOrg;
        }

        return org;
      });

      return newRoom;
    }

    return room;
  });
}

export function formatHotelResult(node) {
  return {
    id: decodeId(node.id),
    idRails: node.id,
    image: node.primaryAttachment?.imageUrl,
    name: node.displayName,
    description: node.address,
    stars: node.stars,
  };
}
