import { post } from './node';
import { API_BASE, CLIENT_ID } from '../constants';
import axios from "axios";

export const login = payload =>
  post(`/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'password',
    scopes: 'bo',
  });

export const refreshToken = () =>
  post(`/oauth/token`, {
    refreshToken: localStorage.getItem('refreshToken'),
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });

export const revokeToken = async (impersonating = false) => {
  await axios.post(`${API_BASE}/api/v1/oauth/revoke`, {
    token: impersonating ? localStorage.getItem('impersonationToken') : localStorage.getItem('accessToken'),
    client_id: CLIENT_ID,
  })
}

export const tokenIntrospection = async () => {
  const response = (await axios.post(`${API_BASE}/api/v1/oauth/introspect`, {
    token: localStorage.getItem('accessToken'),
    client_id: CLIENT_ID,
  })).data
  console.log(response);
  return Boolean(response.active);
}
