import moment from 'moment';
import { cva } from 'class-variance-authority';

export default function AllotmentsEditorRoomDay({ organizationId, room, date, onClick, selected }) {
  const organizationAllotments = room.organizations?.find(
    (org) => parseInt(org.id) === organizationId
  );
  const allotmentDay = organizationAllotments?.allotmentDays?.find(
    (aDay) => {
      return moment(aDay.day).isSame(date, 'day');
    }
  ) ?? {
    state: 'on_request'
  };

  const state = allotmentDay?.state === 'allotment' && allotmentDay?.count <= 0
    ? 'allotment_empty'
    : allotmentDay?.state;

  const dayBadge = cva([
    'relative w-12 h-5 rounded border border-gray-200',
    'flex justify-center items-center text-white',
  ], {
    variants: {
      state: {
        'on_request': 'bg-k-orange',
        'stop_sale': 'bg-red-500',
        'free_sale': 'bg-emerald-400',
        allotment: 'bg-green-600',
      },
      selected: {
        true: 'ring-2 ring-offset-1 ring-blue-500',
        false: 'hover:ring-2 ring-blue-400',
      }
    }
  });

  return (
    <div
      onClick={onClick}
      className={dayBadge({ state: allotmentDay?.state, selected })}
    >
      {state === 'allotment_empty' && (
        <svg className="absolute inset-0 w-full h-full" preserveAspectRatio="none" viewBox="0 0 100 100">
          <defs>
            <pattern id="diagonalLines" patternUnits="userSpaceOnUse" width="10" height="10">
              <path d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2" stroke="white" stroke-width="2" opacity="1"/>
            </pattern>
          </defs>
          <rect width="100" height="100" fill={state === 'allotment_empty' ? 'url(#diagonalLines)' : 'none'}/>
        </svg>
      )}

      {!selected && allotmentDay?.state === 'allotment' && (
        <div className="relative z-10 flex items-center justify-center w-full h-full text-white">
          {allotmentDay.count}
        </div>
      )}

      {selected && (
        <i className="far fa-check"/>
      )}
    </div>
  );
}
