import { AuthContext } from 'contexts';
import { useLocalStorage } from 'react-use';
import { revokeToken, tokenIntrospection } from '../api/auth';
import { Navigate } from 'react-router-dom';

const AuthProvider = ({ children }) => {
  const [realAccessToken, setAccessToken] = useLocalStorage('accessToken', '', {
    raw: true,
  });
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', '', {
    raw: true,
  });
  const [impersonationToken, setImpersonationToken] = useLocalStorage(
    'impersonationToken',
    '',
    {
      raw: true,
    },
  );

  const handleUpdate = ({ accessToken, refreshToken }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const handleReset = async () => {
    await revokeToken();
    setAccessToken('');
    setRefreshToken('');
    setImpersonationToken('');
    return <Navigate to="/account-type" />
  };

  const handleTokenIntrospection = async () => await tokenIntrospection();

  return (
    <AuthContext.Provider
      value={{
        accessToken:
          impersonationToken?.length > 0 ? impersonationToken : realAccessToken,
        refreshToken,
        logout: handleReset,
        onUpdate: handleUpdate,
        impersonating: !!impersonationToken,
        setImpersonationToken,
        isActiveToken: handleTokenIntrospection,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
