import { useField } from 'formik';
import KoobSelect from '../../../components/Koob/KoobSelect';
import { decodeId } from '../../../utils';
import { useApp } from '../../../hooks';
import { useTranslation } from 'react-i18next';

export default function AllotmentsEditorOrganizationSelector({ organizationId, setOrganizationId }) {
  const { t } = useTranslation('allotments');
  const { currentOrganizationId } = useApp();
  const [{ value: rooms }] = useField('');

  const dmcOrganizationId = decodeId(currentOrganizationId);

  const organizations = rooms?.reduce((acc, curr) => {
    curr?.organizations?.forEach(org => {
      const id = decodeId(org.id);
      if (!acc.some(o => o.value === id)) {
        acc.push({
          label: id === dmcOrganizationId
            ? t('modal.dmc')
            : org.name,
          value: id
        });
      }
    });
    return acc;
  }, []);

  return (
    <div>
      <KoobSelect
        value={organizations?.length > 0 ? organizationId : null}
        onSelect={value => setOrganizationId(parseInt(value))}
        options={organizations ?? []}
        placeholder={t('modal.dmc')}
        disabled={organizations?.length === 0}
      />
    </div>
  )
}
