import KoobButton from '../../../../components/Koob/KoobButton';
import { Link } from 'react-router-dom';
import KoobCard from '../../../../components/Koob/KoobCard';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

export default function BulkEditorBottomBar({ loading }) {
  const { t } = useTranslation('toConnectionRequest');
  const form = useFormikContext();

  const resetForm = () => {
    form.resetForm();
  };

  const isFormValid =
    form.values?.toConnectionRequestIds?.length > 0
    && (
      form.values?.toConnectionRequestHotels?.length > 0
      || form.values?.toConnectionRequestExperiences?.length > 0
      || form.values?.toConnectionRequestTemplates?.length > 0
    );

  return (
    <KoobCard>
      <div className="flex justify-between">
        <div className="flex space-x-3">
          <Link to={`/to-connection-request`}>
            <KoobButton>{t('misc:quit')}</KoobButton>
          </Link>

          <KoobButton onClick={() => resetForm()}>
            {t('misc:resetForm')}
          </KoobButton>
        </div>

        <KoobButton
          type="submit"
          loading={loading}
          disabled={!isFormValid}
        >
          {t('bulkEdit.applyButton')}
        </KoobButton>
      </div>
    </KoobCard>
  );
}
