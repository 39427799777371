import { destroy, get, patch, post } from './index';
export const getToConnectionRequest = ({ filters }) =>
  post('/ToConnectionRequest', filters);

export const getToConnectionRequestById = async ({ requestId }) => {
  return get(`ToConnectionRequest/${requestId}`);
};

export const createToConnectionRequestHotels = ({
  toConnectionRequestId,
  payload,
}) => {
  return post(
    `to-connection-requests/${toConnectionRequestId}/request-hotels`,
    payload,
  );
};

export const updateToConnectionRequestHotels = ({
  toConnectionRequestId,
  payload,
}) => {
  return patch(
    `to-connection-requests/${toConnectionRequestId}/request-hotels`,
    payload,
  );
};

export const removeToConnectionRequestHotels = ({
  toConnectionRequestId,
  toConnectionRequestHotelsIds,
}) => {
  return destroy(
    `to-connection-requests/${toConnectionRequestId}/request-hotels`,
    {
      data: { toConnectionRequestHotels: toConnectionRequestHotelsIds },
    },
  );
};

export const updateToConnectionRequest = ({
  toConnectionRequestId,
  payload,
}) => {
  return patch(
    `ToConnectionRequest/${toConnectionRequestId}`,
    payload,
  );
};
