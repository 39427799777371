import React, { useState } from 'react';
import { Input, Text } from '../../../../ui';
import { DataTable } from '../../../../components';
import { MdRemoveRedEye } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import KoobModal from '../../../../components/Koob/KoobModal';
import HotelDetailForm from './HotelDetailForm';
import { IoMdBed } from 'react-icons/io';

export default function HotelsSelected({
  selectedHotels,
  setHotelAttribute,
  onRemoveHotel,
  dmcorganization,
  toorganization,
  isAllowedToManage,
}) {
  const { t } = useTranslation('toConnectionRequest');

  const [showHotelDetailsModal, setShowHotelDetailsModal] = useState(false);
  const [hotel, setHotel] = useState(undefined);

  const headings = [
    {
      slug: 'displayName',
      label: t('hotelName'),
      width: '80px',
      cannotBeReordered: true,
    },
    {
      slug: 'country',
      label: t('countryName'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'city',
      label: t('cityName'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'margin',
      label: t('margin'),
      width: '40px',
      cannotBeReordered: true,
    },
    {
      slug: 'isMarginPercent',
      label: t('percent'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'selected',
      label: t('enabled'),
      width: '40px',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      label: '',
      width: '40px',
      cannotBeReordered: true,
    },
  ];

  const hotels = (selectedHotels || []).map((node, index) => ({
    displayName: {
      value: node?.displayName,
    },
    country: {
      value: node?.country,
    },
    city: {
      value: node?.city,
    },
    margin: {
      render: () => (
        <Input
          type="number"
          value={node?.margin}
          onChange={e => {
            setHotelAttribute(node.id, 'margin', e.target.value);
          }}
          disabled={!isAllowedToManage}
        />
      ),
    },
    isMarginPercent: {
      render: () => (
        <input
          type="checkbox"
          checked={node?.isMarginPercent}
          onChange={() =>
            setHotelAttribute(node.id, 'isMarginPercent', !node.isMarginPercent)
          }
          disabled={!isAllowedToManage}
        />
      ),
    },
    selected: {
      render: () =>
        isAllowedToManage ? (
          <button
            className="border border-gray-200 rounded-full p-2 text-lg leading-none"
            onClick={() => onRemoveHotel(node.id)}
          >
            <MdRemoveRedEye className="text-orange-500" />
          </button>
        ) : null,
    },
    actions: {
      render: () =>
        isAllowedToManage ? (
          <button
            className="border border-gray-200 rounded-full p-2 text-lg leading-none"
            onClick={() => {
              setHotel(node);
              setShowHotelDetailsModal(true);
            }}
          >
            <IoMdBed className="text-orange-500" />
          </button>
        ) : null,
    },
  }));

  return (
    <div>
      <Text type="h3">{t('selectedHotels')}</Text>

      <DataTable
        headings={headings}
        data={hotels}
        isSearchable={false}
      />

      {showHotelDetailsModal && (
        <KoobModal
          open={true}
          setOpen={() => setShowHotelDetailsModal(false)}
          widthClass="sm:max-w-xl xl:max-w-4xl overflow-visible"
        >
          <HotelDetailForm
            hotel={hotel}
            onSelectionFinished={() => setShowHotelDetailsModal(false)}
            dmcorganization={dmcorganization}
            toorganization={toorganization}
          />
        </KoobModal>
      )}
    </div>
  );
}
